import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, NgZone, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { App as CapacitorApp } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { AppConfig } from '@luggagehero/shared/app-settings/data-access';
import { Config } from '@luggagehero/shared/environment';
import { InitiatePaymentResult, IPaymentRecord, LegacyOrderLine, PaymentOption } from '@luggagehero/shared/interfaces';
import { SharedErrorService } from '@luggagehero/shared/services/error';
import { SharedStorageService, StorageTarget } from '@luggagehero/shared/services/storage';
import { SharedStripeService } from '@luggagehero/shared/services/stripe';
import { SharedUserService } from '@luggagehero/shared/services/users';
import {
  SharedUiApplePayButtonComponent,
  SharedUiButtonComponent,
  SharedUiGooglePayButtonComponent,
  SharedUiPaymentCardButtonComponent,
  SharedUiPaypalButtonComponent,
  SharedUiSpinnerComponent,
} from '@luggagehero/shared/ui';
import { TranslatePipe } from '@luggagehero/shared/ui-pipes';
import { SharedUtilString, SharedUtilWindow } from '@luggagehero/shared/util';
import { SharedFeatureBookingsSimpleOrderSummaryComponent } from '@luggagehero/shared-feature-bookings-simple-order-summary';
import { SharedFeatureFluentAuthComponent } from '@luggagehero/shared-feature-fluent-auth';
import { SharedFeatureSelectPaymentMethodComponent } from '@luggagehero/shared-feature-select-payment-method';
import { PaymentRequest, PaymentRequestPaymentMethodEvent } from '@stripe/stripe-js';
import { firstValueFrom } from 'rxjs';

import { GuestDropoffStepBaseComponent } from '../guest-dropoff-step-base-component';
import { PaymentMethodSelectionStepProperties } from './payment-method-selection-step-properties';

@Component({
  selector: 'lh-payment-method-selection-step',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    SharedFeatureFluentAuthComponent,
    SharedFeatureSelectPaymentMethodComponent,
    SharedUiApplePayButtonComponent,
    SharedUiButtonComponent,
    SharedUiGooglePayButtonComponent,
    SharedUiPaymentCardButtonComponent,
    SharedUiPaypalButtonComponent,
    SharedUiSpinnerComponent,
    TranslatePipe,
    SharedFeatureBookingsSimpleOrderSummaryComponent,
  ],
  templateUrl: './payment-method-selection-step.component.html',
  styleUrl: './payment-method-selection-step.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentMethodSelectionStepComponent extends GuestDropoffStepBaseComponent<PaymentMethodSelectionStepProperties> {
  @Output() public paymentMethodAuthenticationError = new EventEmitter<void>();
  @ViewChild('paymentMethodSelector') public paymentMethodSelector: SharedFeatureSelectPaymentMethodComponent;
  @ViewChild('auth') public auth: SharedFeatureFluentAuthComponent;

  public payment: IPaymentRecord<InitiatePaymentResult>;
  public isPaymentMethodSelectorInitialized = false;
  public loadingMessage = '';

  private browserPaymentRequest: PaymentRequest;
  private _isAppUrlOpenEventListenerInitialised = false;
  private _isLoggedIn = false;
  /**
   * Whether a child component, either responsible for authentication or selection of an existing payment method/adding
   * a new payment card, is in a state where it can be submitted.
   */
  private canSubmit = false;

  private static availablePaymentOptions: PaymentOption[];

  private userService = inject(SharedUserService);
  private storageService = inject(SharedStorageService);
  private stripeService = inject(SharedStripeService);
  private error = inject(SharedErrorService);
  private ngZone = inject(NgZone);

  constructor() {
    super();

    this.addSubscription(
      this.userService.loginAnnounced.subscribe(() => {
        this.isLoggedIn = this.userService.isLoggedIn;

        if (this.isLoggedIn && this.isOrderFree) {
          // If the user is logged in and the order is free, we can proceed
          void this.goForward();
        }
      }),
    );
  }

  public get isLoggedIn(): boolean {
    return this._isLoggedIn;
  }
  private set isLoggedIn(value: boolean) {
    this._isLoggedIn = value;
    this.cd.markForCheck();
  }

  public get availablePaymentOptions(): PaymentOption[] {
    return PaymentMethodSelectionStepComponent.availablePaymentOptions;
  }
  private set availablePaymentOptions(value: PaymentOption[]) {
    PaymentMethodSelectionStepComponent.availablePaymentOptions = value;
  }

  public get selectedPaymentOption(): PaymentOption {
    return this.bookingDraft.paymentOption;
  }
  public set selectedPaymentOption(value: PaymentOption) {
    if (value !== this.selectedPaymentOption) {
      this.bookingDraft.paymentOption = value;
      this.canSubmit = false;
      this.cd.markForCheck();
    }
  }

  public get paymentAmount(): number {
    // TODO: Handle zero-decimal currencies from Stripe (https://stripe.com/docs/currencies#zero-decimal)
    return Math.round((this.payment?.data.amount || 0) * 100);
  }

  public get paymentCurrency(): string {
    return this.payment?.data.currency || AppConfig.MERCHANT_CURRENCY;
  }

  public get isOrderFree(): boolean {
    if (!this.order) {
      return false;
    }
    if (this.order.total > 0) {
      // Order is not free if the total is greater than zero
      return false;
    }
    if (this.order.orderLines.filter((ol) => this.included(ol)).reduce((sum, ol) => sum + ol.unitPrice, 0) > 0) {
      // Order is not free if the sum of the unit price of all included order lines is greater than zero
      return false;
    }
    // Order is free
    return true;
  }

  protected get defaultProperties(): PaymentMethodSelectionStepProperties {
    const properties: PaymentMethodSelectionStepProperties = {
      enableApplePay: true,
      enableGooglePay: true,
      requireOrder: true,
      createBooking: true,
    };

    if (this.isOrderFree) {
      properties.header = 'CONTACT_INFO';
      properties.body = ['PROVIDE_EMAIL_TO_CONFIRM_DROPOFF'];
    } else {
      properties.header = 'PAYMENT';
      properties.body = ['SELECT_PAYMENT_METHOD_TO_CONFIRM_DROPOFF'];
    }

    return properties;
  }

  public get isApplePayAvailable(): boolean {
    return this.availablePaymentOptions.includes('ApplePay');
  }

  public get isGooglePayAvailable(): boolean {
    return this.availablePaymentOptions.includes('GooglePay');
  }

  public get showFooter(): boolean {
    // if (this.selectedPaymentOption === 'PaymentCard') {
    //   // Don't show the footer until a payment method has been selected
    //   return this.paymentMethod !== undefined;
    // }
    return true;
  }

  protected async onInit(): Promise<void> {
    // We handle the navigation ourselves
    this.useDefaultNavigation = false;

    if (this.availablePaymentOptions) {
      // Set the previously selected payment option
      this.selectPaymentOption(this.selectedPaymentOption);
    } else {
      // Wait for Stripe and then set the payment options
      await this.stripeService.init();

      const paymentOptions: PaymentOption[] = ['PaymentCard', 'Paypal'];
      if (this.stripeService.isApplePayAvailable) {
        paymentOptions.push('ApplePay');
      }
      if (this.stripeService.isGooglePayAvailable) {
        paymentOptions.push('GooglePay');
      }
      this.availablePaymentOptions = paymentOptions;

      // Select the default payment option
      this.selectPaymentOption();
    }

    await this.initiatePayment(false);
  }

  public onApplePayClick(event: Event): void {
    if (!Config.isProduction) {
      console.log('Apple Pay button clicked', event);
    }
    this.showBrowserPayment();
  }

  public onGooglePayClick(event: Event): void {
    if (!Config.isProduction) {
      console.log('Google Pay button clicked', event);
    }
    this.showBrowserPayment();
  }

  public onPaypalClick(event: Event): void {
    if (!Config.isProduction) {
      console.log('Paypal button clicked', event);
    }
    void this.confirmBookingWithPaypal();
  }

  /**
   * Handles the payment method event from the browser payment request implemented via Stripe. This event emits only if
   * Apple Pay or Google Pay or a similar wallet type payment method has been used to pay for the order, so we can
   * continue directly with the next step rather than expecting the user to further confirm.
   * @param e The payment method event
   */
  private async onBrowserPaymentMethod(e: PaymentRequestPaymentMethodEvent): Promise<void> {
    await this.loader.load(
      async () => {
        const res = await this.stripeService.confirmBrowserPayment(this.payment.data.client_secret, e);
        await this.addPaymentMethod({ provider: 'stripe', data: res });
      },
      (err) => {
        // The process failed, show the error to the user
        // this.notify.error(SharedUtilString.errorToString(err));

        if (!Config.isProduction) {
          console.debug(SharedUtilString.formatPaymentEvent(this.payment.data, 'failed'), err);
        }
      },
    );
  }

  public onCanSubmitChange(value: boolean): void {
    this.canSubmit = value;
    void this.checkCanGoForward(false);
  }

  public onSubmitPaymentMethod(value: IPaymentRecord): void {
    void this.loader.load(() => this.addPaymentMethod(value));
  }

  /**
   * Handles the payment method change event from the payment method selection component. This event is emitted when
   * the user selects a payment method from the list of saved payment methods in their account. Initially emits the
   * default payment method, and also emits null when the user chooses to add a new payment method. Therefore, we
   * should not proceed until the user explicitly confirms their choice.
   * @param value The selected payment method
   */
  public async onSelectedPaymentMethodChange(value: IPaymentRecord): Promise<void> {
    // Update the payment method choice
    this.paymentMethod = value;
    this.canSubmit = false;

    // Check if we are ok to proceed (will enable the forward button if so)
    await this.checkCanGoForward(false);
  }

  public selectPaymentOption(value?: PaymentOption): void {
    let paymentOption = value;

    if (!paymentOption) {
      // Default to Apple Pay or Google Pay if available, otherwise Payment Card
      paymentOption = this.isApplePayAvailable ? 'ApplePay' : this.isGooglePayAvailable ? 'GooglePay' : 'PaymentCard';
    }

    if (!this.isPaymentMethodSelectorInitialized && paymentOption === 'PaymentCard') {
      this.isPaymentMethodSelectorInitialized = true;
    }

    this.selectedPaymentOption = paymentOption;

    void this.checkCanGoForward(false);
  }

  protected onCheckCanGoForward(): boolean {
    switch (this.selectedPaymentOption) {
      case 'ApplePay':
      case 'GooglePay':
      case 'Paypal':
        // For wallet payments, the button should always be enabled
        return true;

      case 'PaymentCard':
      default:
        //
        // Allow proceeding if the user has selected a payment method or if the payment method selector is in a state where it
        // can be submitted
        //
        return this.paymentMethod || this.canSubmit ? true : false;
    }
  }

  public async confirm(): Promise<void> {
    if (!this.isLoggedIn) {
      return this.auth.continue();
    }

    if (this.paymentMethod != null || this.isOrderFree) {
      // We have a payment method or no payment is needed, so we can proceed
      return this.goForward();
    }

    if (this.paymentMethodSelector && this.canSubmit) {
      this.paymentMethodSelector.confirm();
    }
  }

  private async initiatePayment(forceNewIntent: boolean): Promise<void> {
    if (!this.order) {
      // This should never happen as this step is configured to require an order
      return;
    }

    //
    // By providing a storage location id we tell the server to validate the payment card with an up front payment if
    // necessary for the given storage location
    //
    const storageLocationId = this.order.orderRequest.storageLocationId;
    const paymentId = this.payment?.data.id;
    const orderId = this.order._id;
    const returnUrl = this.windowService.isNative ? AppConfig.STRIPE_PAYPAL_NATIVE_RETURN_URL : null;

    try {
      // Initiate payment so we know what amount, if any, will be paid or reserved up front for this order
      this.payment =
        paymentId && !forceNewIntent
          ? await this.paymentService.reinitiatePayment('stripe', paymentId, storageLocationId, orderId)
          : await this.paymentService.initiatePayment('stripe', storageLocationId, orderId, undefined, returnUrl);

      await this.updateBrowserPayment();
    } catch (err) {
      this.error.handleError(err, this.translateService.instant('ERROR_INITIATING_PAYMENT'));
    }
  }

  private async addPaymentMethod(value: IPaymentRecord): Promise<void> {
    this.canSubmit = false;

    try {
      const paymentMethod = await this.paymentService.addPaymentMethod(value);
      await this.onSelectedPaymentMethodChange(paymentMethod);
      await this.goForward();
    } catch (err) {
      this.error.handleError(err, this.translateService.instant('ERROR_ADDING_PAYMENT_METHOD'));
    }
  }

  private async updateBrowserPayment(): Promise<void> {
    if (this.browserPaymentRequest) {
      this.browserPaymentRequest.update({
        total: {
          amount: this.paymentAmount,
          label: this.translateService.instant(AppConfig.PAYMENT_VERIFICATION_LABEL),
        },
        currency: this.paymentCurrency,
      });
    } else {
      this.browserPaymentRequest = await this.stripeService.requestBrowserPayment(
        this.paymentAmount,
        this.paymentCurrency,
        AppConfig.MERCHANT_COUNTRY_CODE,
        this.translateService.instant(AppConfig.PAYMENT_VERIFICATION_LABEL),
      );

      if (this.browserPaymentRequest) {
        // Register payment method event handler
        this.browserPaymentRequest.on('paymentmethod', (e) => this.onBrowserPaymentMethod(e));
      }
    }
  }

  private showBrowserPayment(): void {
    if (!this.payment || !this.browserPaymentRequest || this.browserPaymentRequest.isShowing()) {
      return;
    }
    this.browserPaymentRequest.show();
  }

  private async confirmBookingWithPaypal(): Promise<void> {
    if (this.windowService.isNative) {
      await this.addAppUrlOpenEventListener();
      return Browser.open({ url: this.payment.data.redirectUrl });
    }

    this.storageService.remove('PAYPAL_SETUP_INTENT', StorageTarget.Local);
    this.storageService.remove('PAYPAL_PAYMENT_INTENT', StorageTarget.Local);
    this.storageService.remove('PAYPAL_SETUP_INTENT_CLIENT_SECRET', StorageTarget.Local);
    this.storageService.remove('PAYPAL_PAYMENT_INTENT_CLIENT_SECRET', StorageTarget.Local);

    const redirectUrl = '/providers/stripe/paypal.html?intent=' + this.payment.data.client_secret;

    const window = await firstValueFrom(this.windowService.openCentered(redirectUrl, '', false));
    let paypalAuthenticationStatus = await firstValueFrom(
      SharedUtilWindow.waitForValue(window, 'PAYPAL_REDIRECT_STATUS'),
    );

    if (!this.storageService.isLocalStorageSupported && !this.storageService.isSessionStorageSupported) {
      this.error.handleError(
        new Error(`Local and session storage not available (client_secret: ${this.payment.data.client_secret})`),
      );

      return;
    }

    let setupIntentId: string, paymentIntentId: string;

    if (this.storageService.isLocalStorageSupported) {
      setupIntentId = this.storageService.get('PAYPAL_SETUP_INTENT', StorageTarget.Local);
      paymentIntentId = this.storageService.get('PAYPAL_PAYMENT_INTENT', StorageTarget.Local);
    } else {
      setupIntentId = this.storageService.get('PAYPAL_SETUP_INTENT', StorageTarget.Session);
      paymentIntentId = this.storageService.get('PAYPAL_PAYMENT_INTENT', StorageTarget.Session);

      if (!paypalAuthenticationStatus) {
        paypalAuthenticationStatus = this.storageService.get('PAYPAL_REDIRECT_STATUS', StorageTarget.Session);
      }
    }

    if (
      (paypalAuthenticationStatus === 'succeeded' || paypalAuthenticationStatus === 'pending') &&
      (setupIntentId || paymentIntentId)
    ) {
      await this.loader.load(() => this.addPaymentMethod({ provider: 'stripe', data: { id: setupIntentId } }));
    } else if (paypalAuthenticationStatus === 'failed') {
      await this.initiatePayment(true);
      this.paymentMethodAuthenticationError.emit();
    } else {
      await this.initiatePayment(true);
    }
  }

  private async addAppUrlOpenEventListener(): Promise<void> {
    if (this._isAppUrlOpenEventListenerInitialised) {
      return;
    }
    this._isAppUrlOpenEventListenerInitialised = true;
    await CapacitorApp.removeAllListeners();

    await CapacitorApp.addListener('appUrlOpen', (event) => {
      void this.ngZone.run(async () => {
        if (event.url) {
          const url = new URL(event.url);
          if (
            url &&
            url.protocol === 'luggagehero:' &&
            (url.pathname.endsWith('stripe-paypal-redirect') || url.host.endsWith('stripe-paypal-redirect')) &&
            url.searchParams.get('redirect_status')
          ) {
            void Browser.close();

            const redirectStatus = url.searchParams.get('redirect_status');
            if (['succeeded', 'pending'].includes(redirectStatus)) {
              const setupIntentId = url.searchParams.get('setup_intent');
              await this.loader.load(() => this.addPaymentMethod({ provider: 'stripe', data: { id: setupIntentId } }));
            } else if (redirectStatus === 'failed') {
              // TODO: Handle failed payment
              // void this.showPaymentCardForm();
            }
          }
        }
      });
    });
  }

  private included(orderLine: LegacyOrderLine): boolean {
    // Consider an order line included if it is not optional or if it is optional and selected
    return !orderLine.optional || orderLine.selected;
  }
}
